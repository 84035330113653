import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store.tsx";
import _ from "lodash";
import OrderArticle, { getTotalPrice } from "../../models/order/OrderArticle.ts";
import { VoucherDefV2, VoucherV2 } from "../vouchersV2Slice.tsx";
import { selectVouchersV2 } from "./selectVouchersV2.tsx";
import { ArticleType } from "../../models/menu/Article.ts";
import { selectSalesAreaPriceLineId } from "../../useSalesAreaPriceLineId.ts";

const sorting: { [key in VoucherDefV2["discountType"]]: number } = {
  COLLECTION: 0,
  ADD_DISCOUNTED_PRODUCT: 1,
  PERCENTAGE_OFF_PRODUCT: 2,
  AMOUNT_OFF_PRODUCT: 3,
  PERCENTAGE_OFF_TOTAL: 4,
  AMOUNT_OFF_TOTAL: 5,
};

export function calculateDiscountForVoucher(
  voucher: VoucherV2,
  orderArticles: OrderArticle[],
  priceLineId: number | null,
  currentDiscounts: {
    [orderArticleUuid: string]: {
      discount: number;
    };
  }
): {
  [orderArticleUuid: string]: {
    discount: number;
  };
} {
  const newDiscounts: {
    [orderArticleUuid: string]: {
      discount: number;
    };
  } = _.chain(orderArticles)
    .keyBy("uuid")
    .mapValues(() => ({ discount: 0 }))
    .value();
  for (let i = 0; i < voucher.voucher.number_of_times; i++) {
    if (voucher.voucherdef.discountType == "PERCENTAGE_OFF_TOTAL") {
      const discountPercentage = voucher.voucherdef.priceDiscountPercentage;
      orderArticles.forEach((orderArticle) => {
        const discount =
          (Math.round(
            (((getTotalPrice(orderArticle, priceLineId, {
              count: 1,
              includeArticleTypes: { [ArticleType.Regular]: true },
            }) -
              currentDiscounts[orderArticle.uuid].discount / orderArticle.count) *
              discountPercentage) /
              100 -
              Number.EPSILON) *
              100
          ) /
            100) *
          orderArticle.count;
        currentDiscounts[orderArticle.uuid].discount += discount;
        newDiscounts[orderArticle.uuid].discount += discount;
      });
    } else if (voucher.voucherdef.discountType == "PERCENTAGE_OFF_PRODUCT") {
      const priceDiscountPercentage = Number(voucher.voucherdef.priceDiscountPercentage);
      let maxChooseItems = voucher.voucherdef.maxChooseItems;
      const voucherdef = voucher.voucherdef;
      const productIdsMap = _.keyBy(voucher.voucherdef.includedProducts_JSON);
            const productApiId1sMap = _.keyBy(voucher.voucherdef.includedProducts, "apiId1");

      orderArticles
              .filter(
                (orderArticle) =>
                  productIdsMap[orderArticle.article.id] ||
                  (productApiId1sMap[orderArticle.article.apiId1] &&
                    productApiId1sMap[orderArticle.article.apiId1].apiId2 === orderArticle.article.apiId2)
              )
        .sort((a, b) => getTotalPrice(b, priceLineId, { count: 1 }) - getTotalPrice(a, priceLineId, { count: 1 }))
        .some((orderArticle) => {
          const toDeduct =
            Math.round(
              (((getTotalPrice(orderArticle, priceLineId, { count: 1 }) -
                currentDiscounts[orderArticle.uuid].discount / orderArticle.count) *
                priceDiscountPercentage) /
                100 -
                Number.EPSILON) *
                100
            ) / 100;
          for (let i = 0; i < orderArticle.count; i++) {
            currentDiscounts[orderArticle.uuid].discount += toDeduct;
            newDiscounts[orderArticle.uuid].discount += toDeduct;
            if (maxChooseItems > 0) {
              maxChooseItems--;
            }
            if (voucherdef.maxChooseItems > 0 && maxChooseItems === 0) {
              return true;
            }
          }
          return voucherdef.maxChooseItems > 0 && maxChooseItems === 0;
        });
    } else if (voucher.voucherdef.discountType == "AMOUNT_OFF_TOTAL") {
      let totalDiscountAmount = voucher.voucherdef.priceDiscountAmount;
      orderArticles.some((orderArticle) => {
        const toDeduct = Math.min(
          getTotalPrice(orderArticle, priceLineId) - currentDiscounts[orderArticle.uuid].discount,
          totalDiscountAmount
        );
        currentDiscounts[orderArticle.uuid].discount += toDeduct;
        newDiscounts[orderArticle.uuid].discount += toDeduct;
        totalDiscountAmount -= toDeduct;
        return totalDiscountAmount <= 0;
      });
    } else if (voucher.voucherdef.discountType == "AMOUNT_OFF_PRODUCT") {
      const totalDiscountAmount = Number(voucher.voucherdef.priceDiscountAmount);
      let max_discounted_amount =
        voucher.voucherdef.max_discounted_amount != null && voucher.voucherdef.max_discounted_amount !== 0
          ? voucher.voucherdef.max_discounted_amount / 100.0
          : null;

      let maxChooseItems = voucher.voucherdef.maxChooseItems;
      const voucherdef = voucher.voucherdef;
      const productIdsMap = _.keyBy(
        _.concat(voucher.voucherdef.includedProducts_JSON, voucher.voucherdef.applied_on_product_ids_through_menus)
      );
            const productApiId1sMap = _.keyBy(voucher.voucherdef.includedProducts, "apiId1");

      orderArticles
              .filter(
                (orderArticle) =>
                  productIdsMap[orderArticle.article.id] ||
                  (productApiId1sMap[orderArticle.article.apiId1] &&
                    productApiId1sMap[orderArticle.article.apiId1].apiId2 === orderArticle.article.apiId2)
              )
        .sort((a, b) => getTotalPrice(b, priceLineId, { count: 1 }) - getTotalPrice(a, priceLineId, { count: 1 }))
        .some((orderArticle) => {
          let toDeduct =
            Math.round(
              (Math.min(
                getTotalPrice(orderArticle, priceLineId, { count: 1 }) -
                  currentDiscounts[orderArticle.uuid].discount / orderArticle.count,
                totalDiscountAmount,
                max_discounted_amount === null ? Number.MAX_VALUE : max_discounted_amount
              ) -
                Number.EPSILON) *
                100
            ) / 100;
          for (let i = 0; i < orderArticle.count; i++) {
            if (max_discounted_amount === null) {
              currentDiscounts[orderArticle.uuid].discount += toDeduct;
              newDiscounts[orderArticle.uuid].discount += toDeduct;
            } else {
              toDeduct = Math.min(max_discounted_amount, toDeduct);
              currentDiscounts[orderArticle.uuid].discount += toDeduct;
              newDiscounts[orderArticle.uuid].discount += toDeduct;
              max_discounted_amount -= toDeduct;
            }

            if (maxChooseItems > 0) {
              maxChooseItems--;
            }
            if (voucherdef.maxChooseItems > 0 && maxChooseItems === 0) {
              return true;
            }
          }
          return voucherdef.maxChooseItems > 0 && maxChooseItems === 0;
        });
    }
  }
  return newDiscounts;
}

export const selectVoucherDiscounts = createSelector(
  [selectVouchersV2, (state: RootState) => state.shoppingCart.items, selectSalesAreaPriceLineId],
  (vouchers, orderArticles, priceLineId) => {
    const discounts: {
      [orderArticleUuid: string]: {
        discount: number;
      };
    } = _.chain(orderArticles)
      .keyBy("uuid")
      .mapValues(() => ({ discount: 0 }))
      .value();

    [...vouchers]
      .sort((a, b) => sorting[a.voucherdef.discountType] - sorting[b.voucherdef.discountType])
      .filter((voucher) => minBasketPriceValidation(voucher, orderArticles, priceLineId))
      .forEach((voucher: VoucherV2) => {
        calculateDiscountForVoucher(voucher, orderArticles, priceLineId, discounts);
      });

    return { discountsPerOrderArticle: discounts };
  }
);

function minBasketPriceValidation(
  voucher: VoucherV2,
  orderArticles: OrderArticle[],
  priceLineId: number | null
): boolean {
  if (voucher.voucherdef.minBasketPrice != null && voucher.voucherdef.minBasketPrice > 0) {
    return (
      _.sumBy(orderArticles, (orderArticle) => getTotalPrice(orderArticle, priceLineId)) >=
      voucher.voucherdef.minBasketPrice
    );
  } else {
    return true;
  }
}
